body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
}

html, body, #root {
  height: 100%;
}

@font-face {
  font-family: "Open Sans Extrabold";
  src: url('/fonts/openSans.ttf');
}

@font-face {
  font-family: "Roboto Regular";
  src: url('/fonts/Roboto/Roboto-Regular.ttf');
}

*:not(.default-scrollbar)::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    background-color: #F5F7F9;
}
*::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 10px;
    background-color: #45494c;
}
*::-webkit-scrollbar-corner {
    background-color: transparent;
}

.gm-fullscreen-control {
 display: none!important;
}


.headerOrders {
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: normal;
    color: #818286;
    white-space: nowrap;
    padding-left: 12px;
}

.headerOrders:first-child {
    padding-left: 24px;
}
.ordersRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #E6EAED;
    justify-content: center;
}
.ordersRow:last-child {
    border-bottom: 0 solid transparent;
}
.titleRow {
    background: #F5F7F9;
    color: #2E83FF;
    padding-left: 12px;
}
.titleRowSummary {
    background: #F5F7F9;
    color: #2E83FF;
}

.titleRowPayments {
    background: #F5F7F9;
}

.model_creation_text_field {
    color: #2A79EE;
    background: #E1EEFF;
    opacity: 0.7;
    border: 0
}

.fullscreen {
    z-index: 9999;
    width: 100%;
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0 !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
}

img.small-logo {
    width: 175px;
}

@media screen and (max-width: 768px) {
    html, body, #root {
        height: auto;
    }
}
